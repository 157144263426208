<template>
  <app-wrapper>
    <template v-slot:child>
      <!--Report sheet -->
      <div class="w-90 center">
        <div class="flex justify-between items-center pv4-l pv3 mini-spacing">
          <div>
            <p class="pb2 f3 b">Report <strong><font-awesome-icon icon="fa-solid fa-question-circle fa-2xs"
                  class="text-muted clickModalIcon" @click="clickModalReports = true"
                  style="cursor: pointer; padding-bottom: 2px;" /></strong>
            </p>
            <div class="dashboard-inquiry-modal" v-if="clickModalReports">You can generate a report for the performance
              of your business on a daily, weekly or monthly basis. Click <a href="#" target="_blank"
                style="color: blue">here</a> to
              learn more.
            </div>
          </div>
          <div>
            <button class="btn2 buttonhoverall">Export</button>
          </div>
        </div>
      </div>
      <!--Report sheet -->

      <!-- total profit, Expenses, Sales -->
      <div class="flex flex-wrap w-90 center mt3 adj-text">
        <div class="box-border pa3 w-third-l w-50">
          <div class="flex items-center pb3">
            <img :src="require('@/assets/images/qty-sale.svg')" alt=" " />
            <div class="pl2">Gross Profit</div>
          </div>
          <div class="f3 b">{{ formatAmount(profits, $store.state.Settings?.currency) }}</div>
        </div>

        <div class="box-border pa3 w-third-l w-50">
          <div class="flex items-center pb3">
            <img :src="require('@/assets/images/qty-sale.svg')" alt=" " />
            <div class="pl2">Total Expenses</div>
          </div>
          <div class="f3 b">{{ formatAmount(expenses, $store.state.Settings?.currency) }}</div>
        </div>

        <div class="box-border pa3 w-third-l w-50">
          <div class="flex items-center pb3">
            <img :src="require('@/assets/images/qty-sale.svg')" alt=" " />
            <div class="pl2">Total Sales</div>
          </div>
          <div class="f3 b">{{ formatAmount(sales, $store.state.Settings?.currency) }}</div>
        </div>
      </div>
      <!-- total profit, Expenses, Sales -->

      <!-- advanced report -->
      <div class="w-90 center mt5 f4 font-w2">Advanced Reports</div>
      <div class="flex flex-wrap w-90 center mt4 mb4" style="gap: .3rem">
        <div class="w-33-l w-100 box-border pa4 mb0-l mb4 position-relative">
          <img :src="require('@/assets/images/money-box.svg')" class="pb2" alt=" " />
          <div class="b pb2">Profit and Loss</div>
          <div class="pb2 lh-copy">
            Statement of Financial Position for {{ orgData?.org }} as at {{ startDate }} - {{ endDate }}
          </div>

          <router-link 
            v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(19)"
            :to="'#'"
            @click="routeTo('ProfitAndLoss')"
          >
            <div class="font-w1" style="color: #132c8c">View report &#62;</div>
          </router-link>
          <div v-else class="font-w1" style="color: #132c8c">No Access to view</div>
          <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
          </span>
        </div>

        <div class="w-33-l w-100 box-border pa4 mb0-l mb4 position-relative">
          <img :src="require('@/assets/images/money-box.svg')" class="pb2" alt=" " />
          <div class="b pb2">Balance Sheet</div>
          <div class="pb2 lh-copy">
            Statement of Financial Position for {{ orgData?.org }} as at {{ startDate }} - {{ endDate }}
          </div>
          <div v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(20)" class="w-90 f4 font-w1">
            <router-link 
              :to="'#'" 
              style="color: #132c8c"
              @click="routeTo('BalanceSheet')"
            >View report</router-link>
          </div>
          <div v-else class="font-w1" style="color: #132c8c">No Access to view</div>
          <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
          </span>
        </div>

        <div class="w-33-l w-100 box-border pa4 mb0-l mb4 position-relative">
          <img :src="require('@/assets/images/money-box.svg')" class="pb2" alt=" " />
          <div class="b pb2">Cashflow Statement</div>
          <div class="pb2 lh-copy">Cashflow Statement for {{ orgData?.org }} as at {{ startDate }} - {{ endDate }}
          </div>
          <!-- <div class="font-w1" style="color: #132c8c">View report &#62;</div> -->
          <div class="w-90 f4 font-w2">Coming Soon...</div>
          <span v-if="plan?.businessPlan === 'Micro Business Plan'" class="pro-badge">
            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
          </span>
        </div>
      </div>
      <div class="flex flex-wrap w-90 center mt4 mb4" style="gap: .3rem">
        <div class="w-33-l w-100 box-border pa4 mb0-l mb4 position-relative">
          <img :src="require('@/assets/images/money-box.svg')" class="pb2" alt=" " />
          <div class="b pb2">Sales Report</div>
          <div class="pb2 lh-copy">Sales Report for {{ orgData?.org }} as at {{ startDate }} - {{ endDate }}</div>
          <router-link 
            :to="'#'"
            v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(16)"
            @click="routeTo('SalesReport')"
          >
            <div class="font-w1" style="color: #132c8c">View report &#62;</div>
          </router-link>
          <div v-else class="font-w1" style="color: #132c8c">No Access to view</div>
          <span v-if="plan?.businessPlan === 'Micro Business Plan' || plan?.businessPlan === 'Professional Plan'" class="pro-badge">
            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
          </span>
        </div>
        <div class="w-33-l w-100 box-border pa4 mb0-l mb4 position-relative">
          <img :src="require('@/assets/images/money-box.svg')" class="pb2" alt=" " />
          <div class="b pb2">Expense Report</div>
          <div class="pb2 lh-copy">Expense Report for {{ orgData?.org }} as at {{ startDate }} - {{ endDate }}</div>

          <router-link 
            :to="'#'"
            v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(17)"
            @click="routeTo('ExpenseReport')"
          >
            <div class="font-w1" style="color: #132c8c">View report &#62;</div>
          </router-link>
          <div v-else class="font-w1" style="color: #132c8c">No Access to view</div>
          <span v-if="plan?.businessPlan === 'Micro Business Plan' || plan?.businessPlan === 'Professional Plan'" class="pro-badge">
            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
          </span>
        </div>

        <div class="w-33-l w-100 box-border pa4 mb0-l mb4 position-relative">
          <img :src="require('@/assets/images/money-box.svg')" class="pb2" alt=" " />
          <div class="b pb2">Inventory Report</div>
          <div class="pb2 lh-copy">Inventory Report for {{ orgData?.org }} as at {{ startDate }} - {{ endDate }}</div>
          <router-link 
            :to="'#'"
            v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(18)"
            @click="routeTo('InventoryReportList')"
          >
            <div class="font-w1" style="color: #132c8c">
              View report &#62;
            </div>
          </router-link>
          <div v-else class="font-w1" style="color: #132c8c">No Access to view</div>
          <span v-if="plan?.businessPlan === 'Micro Business Plan' || plan?.businessPlan === 'Professional Plan'" class="pro-badge">
            <font-awesome-icon icon="fa-solid fa-lock" class="fa-2x " />
          </span>
        </div>
      </div>
    </template>
  </app-wrapper>
</template>

<script>
  import { onMounted, computed, watch, ref } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import Swal from 'sweetalert2'
  import moment from 'moment'
  import AppWrapper from '@/layout/AppWrapper'
  import { formatAmount, formatDate } from '@/utils/lib'

  export default {
    name: 'ReportNew',
    components: { AppWrapper },

    setup() {
      const store = useStore()
      const router = useRouter()

      const profits = computed(() => store.state.Reporting.profits)
      const sales = computed(() => store.state.Reporting.sales)
      const expenses = computed(() => store.state.Reporting.expenses)
      const orgData = computed(() => store?.state?.Auth?.userData)
      const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
      const role = computed(() => store?.state?.Auth?.role)
      const plan = computed(() => store.state?.Settings?.paymentPlan)

      const month = new Date().getMonth()
      const year = new Date().getFullYear()
      const day = new Date().getDate()

      const startDate = formatDate(new Date(year, 0, 1))
      const endDate = formatDate(new Date(year, month, day))

      const routeTo = (path) => {
          if (plan.value.businessPlan === 'Micro Business Plan') {
              Swal.fire({
                  title: `Upgrade Plan`,
                  text: `This feature is not available on free plan. Upgrade to unlock!`,
                  showCloseButton: true,
                  confirmButtonText: 'Upgrade',
                  confirmButtonColor: '#a94442',
              }).then(async (result) => {
                  if (result.isConfirmed) {
                      await router.replace({ name: 'Plan' })
                  }
              });
              return;
          }
          router.push({ name: path })
      }

      onMounted(() => {
        window.addEventListener('click', (event) => {
          const modalIcon = document.querySelector('.clickModalIcon')

          if (!(event.target.closest('.clickModalIcon') || event.target === modalIcon) && clickModalReports.value === true) {
            closeModal()
          }
        })
        store.dispatch('Settings/getPlan')
        store.dispatch('Reporting/getReportingProfits')
        store.dispatch('Reporting/getReportingSales')
        store.dispatch('Reporting/getReportingExpenses')
      })

      const clickModalReports = ref(false)
      const closeModal = () => {
        clickModalReports.value = false
      }
      return {
        profits,
        sales,
        expenses,
        formatAmount,
        orgData,
        startDate,
        endDate,
        rolePermissions,
        role,
        clickModalReports,
        closeModal,
        plan,
        routeTo,
      }
    }
  }
</script>

<style scoped></style>